// Captcha.js

import React from 'react';

function Captcha({ imageUrl, onChange }) {
  return (
    <div>
      <img src={imageUrl} alt="CAPTCHA" />
      <input type="text" onChange={onChange} placeholder="Enter CAPTCHA" />
    </div>
  );
}

export default Captcha;
