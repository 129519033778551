/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import '../resources/css/sitemap.css';
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';

export default function Sitemap() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
	const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"CAD",
        siteId: siteID,
        departDate:"",
        countryName:"CA",
        countryCode:"CA",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
  const handleAirlineClicked = async () => {

    const navigationPath = airlines.pageValue.length === 2
      ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
      : `/flights-to/${airlines.url}-${airlines.pageValue}`;

    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        //console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }

    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const openLink = (url) => {
        window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
        };
  return (
    <>
    <MetaTitle pageTitle="Explore Sitemap of Rawfares.co.uk" pageDescription="Check the sitemap to get the details of flights and other information at Rawfares.co.uk." url={`${apiIP}/sitemap`} />
    <div class="container-fluid" >
			
			<div class="row">
			<div class="content_page_title">
			<div class="container">
			<h1><img src="resources/images/icons/tnc-icon.svg" alt=""/> Sitemap</h1>
			</div>
			</div>
				
		<div class="textBox">
        <div class="container">
          <div class="row">
			<div class="col-sm-12">
              
                     <div class="sitemap-wrap">
                        <h3 class="mt-0">Quick Links</h3>
						<ul class="site_map_links">
							<li><Link to='/about'> About Us </Link></li>
							<li><Link to='/contact-us'> Contact Us </Link></li>
              <li><Link to={`${apiIP}/online-check-in`} >Online Check-in</Link></li>
              <li><Link to={`${apiIP}/airline-baggage-fee`} onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Airline Baggage Fees</Link></li>
              <li><Link to='/post-ticketing-fee'>Post Ticketing Fees</Link></li>
              <li><Link to='/our-services-fee' onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Our Service Fees</Link></li>
              <li><Link to='/contact-us'>Contact Us</Link></li>
							<li> Privacy Policy </li>
							<li><Link to='/terms-and-condition'> Terms &amp; Conditions </Link></li>
							
					 </ul>
					 
					
					 
                    </div>
                     <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular Airlines</h3>
						<ul class="site_map_links">
							{/* <li><div onClick={(e) => {handleCardClick("Airline", "dl", "delta-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Delta Airlines</div></li> */}
							{/* <li><div onClick={(e) => {handleCardClick("Airline", "wn", "southwest-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Southwest Airlines</div></li> */}
							<li><div onClick={(e) => {handleCardClick("Airline", "ac", "air-canada"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Air Canada</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "ts", "air-transat"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Air Transat</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "9m", "central-mountain-air"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Central Mountain Air</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "pd", "porter-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Porter Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "wg", "sunwing-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Sunwing Airlines</div></li>
							<li><div onClick={(e) => {handleCardClick("Airline", "ws", "westJet"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>WestJet</div></li>
							{/* <li><div onClick={(e) => {handleCardClick("Airline", "3m", "silver-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Silver Airways</div></li> */}
					 </ul>
                    </div>
                    

                    <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular Domestic Flights</h3>
						<ul class="site_map_links">
							{/* <li><div onClick={(e) => {handleCardClick("City", "las", "las-vegas"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Las Vegas</div></li> */}
							{/* <li><div onClick={(e) => {handleCardClick("City", "mco", "orlando"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Orlando</div></li> */}
              <li><div onClick={(e) => handleCardClick("City", "yyc", "calgary")} style={{cursor: 'pointer'}}>Flights to Calgary</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "yeg", "edmonton"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Edmonton</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "yhz", "halifax"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Halifax</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "yow", "ottawa"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Ottawa</div></li>
              <li><div onClick={(e) => {handleCardClick("City", "yyz", "toronto"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Toronto</div></li>
              <li><div onClick={(e) => {handleCardClick("City", "yvr", "vancouver"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>Flights to Vancouver</div></li>
					 </ul>
                    </div>

                    {/* <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular International Flights</h3>
						<ul class="site_map_links">
							<li><div onClick={(e) => {handleCardClick("City", "ams", "amsterdam") /*e.preventDefault(); openLink(e.currentTarget.href);} style={{cursor: 'pointer'}}>Flights to Amsterdam</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "mex", "mexico-city"); /*e.preventDefault(); openLink(e.currentTarget.href);} style={{cursor: 'pointer'}}>Flights to Mexico City</div></li>
              <li><div onClick={(e) => {handleCardClick("City", "abq", "albuquerque"); /*e.preventDefault(); openLink(e.currentTarget.href);} style={{cursor: 'pointer'}}>Flights to Albuquerque</div></li>
              
							{/* <li><div onClick={(e) => {handleCardClick("City", "cdg", "paris"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Paris</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "sjc", "san-jose"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to San Jose</div></li>
							<li><div onClick={(e) => {handleCardClick("City", "syd", "sydney"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Flights to Sydney</div></li> 
					 </ul>
                    </div> */}
					
					
            </div>	
          </div>
        </div>
      </div>
				
			
				
		      </div>
				
			</div> 
            <Footer/>
            </>
  )
}
