/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import SITE_CONFIG from '../SiteController/SiteController';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Loader from '../resources/loader/loader.gif';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../resources/css/search_engine.css';
import '../resources/css/bootstrap.min.css';
import '../resources/css/jquery-ui.css';
import '../resources/css/fontawesome.all.min.css';
import '../resources/images/favicon.png';
import '../resources/font/bootstrap-icons.css';
import Footer from '../components/Footer/footer'
//import '../resources/jc/owl.carousel.min.css';
//import '../resources/js/jquery.meanmenu.js';
//import '../resources/js/custom.js';
//import '../resources/js/jquery.js';
import '../resources/jc/wow.min.js';
//import '../resources/jc/owl.carousel.min.js';
//import '../resources/jc/main.js';
import swap from '../resources/images/icons/swap-icon.svg';
import maps from '../resources/images/icons/maps.png';
import sale from '../resources/images/icons/sale.png';
import customerService from '../resources/images/icons/customer-service.png';

import Searchbar from '../Shared/Searchbar';
import MetaTitle from './metaTitle.jsx';


export default function Home() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
    const [isLoading, setIsLoading] = useState(false);
    const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"CAD",
        siteId: siteID,
        departDate:"",
        countryName:"CA",
        countryCode:"CA",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
 
const handleAirlineClicked = async () => {

    const navigationPath = airlines.pageValue.length === 2
      ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
      : `/flights-to/${airlines.url}-${airlines.pageValue}`;

    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        //console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      //console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const openLink = (url) => {
		window.open(url, '_blank');
	  };
  return (
    <div className='HomeBody'>
        {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
      {/* <Helmet>
        <title>Cheap Flights | Airline Tickets | Airfare & Discount Deals | Rawfares</title>
        <meta name="description" content="Find Cheap Flights, book discount airfares and air tickets, direct & last-minute deals available on exclusive discount offer." />
        {/* Other meta tags */}
        <link rel="canonical" href={`${apiIP}/`} />
        {/* Additional meta tags for homepage 
      </Helmet> */}
       <MetaTitle pageTitle="Top-Notch Travel Deals Await | RawFares" pageDescription="RawFares brings you fantastic flight and hotel offers. Enjoy our exceptional deals, customer service, and travel plans for a perfect trip." url={`${apiIP}/`} />
    < Searchbar backgroundImage="https://imgfolders.com/rawfares/rawfaresCA/home_banner/banner.png" />

        {/* <!-- mobile Search popup Fly From Start here --> */}
        {/* <div className="mobile_search_popup" id="flyfrom" style={{display:"none"}}>
            <a href="javascript" className="searchwindowclose"><i className="bi bi-x"></i></a>
            <div className="popup_head blockshadow"><span className="icons bi bi-geo-alt-fill"></span> Select Loaction </div>


            <div className="inner_block">
                <div className="destination_field">
                    <span className="line"></span>
                    <div className="input_block from_input relative">
                        <input type="text" placeholder="From" id="fromCityMob" className="inputField mobile_input"/>
                        <span className="label_text change_label">From</span>

                        {/* </input><!--<div className="error_text">Please select an origin</div>--> 
                    </div>
                    <div className="row_margin relative">
                        <a href="javascript" className="swap_icon">
                            <img src={swap} alt="" />
                        </a>
                        <div className="input_block to_input">
                            <input type="text" placeholder="To" id="toCityMob" className="inputField mobile_input"/>
                            <span className="label_text change_label">To</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        {/* <!--mobile Search popup Start here -->
        <!-- mobile Search popup Fly To Start here --> 
        <div className="mobile_search_popup" id="flyto" style={{display:"none"}}>
            <a href="javascript;" className="searchwindowclose"><i className="bi bi-x"></i></a>
            <div className="input_block">
                <div className="blockshadow from_inputmob">
                    <input type="text" placeholder="To" id="toCityMob" className="inputField"/>
                    {/* <!--<span className="label_text change_label">Flying from</span>--> 
                    <span className="icons bi bi-geo-alt-fill"></span>
                </div>
            </div>
        </div>
        {/* <!--mobile Search popup Start here -->
        <!-- mobile calendar popup Start here --> 
        <div className="mobile_search_popup" id="mobileCal" style={{display:"none"}}>
            <a href="javascript" className="searchwindowclose"><i className="bi bi-x"></i></a>
            <div className="row row_margin" id="mobCalDiv">
                <div className="col-12">
                    <div className="dates">
                        <div className="mobile_header text-center" id="mobileDateLabel">Select Depart Date</div>
                        <div className="input_block col-50" id="mobileCalDept">
                            <input type="text" id="datepickerMob" className="inputField cursor" readOnly/>
                            <span className="icons bi bi-calendar3"></span>
                        </div>
                        <div className="input_block col-50" id="mobileCalRet">
                            <input type="text" id="datepickerMob2" className="inputField cursor" readOnly/>
                            <span className="icons bi bi-calendar3"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full_calender">
                <div className="date_cal"></div>
            </div>
        </div> */}
        {/* <!--mobile Search popup Start here --> */}

        {/* <script type="text/javascript" src="resources/js/searchengine.js?5768"></script> */}

        {/* <!--container-content--> */}
        <div className="relative">

            {/* <!-- ServicesBox Start--> */}
           
            {/* <!-- ServicesBox End--> */}

            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="dealBox_image">
                            <h2 className="page_title">Popular Domestic Flight Picks</h2>
                            <p>From the iconic skyscrapers of Toronto to the sun-soaked shores of Vancouver Island, these destinations have won the hearts of adventurers far and wide. Immerse yourself in the cultural mosaic of Montreal, explore the historical marvels of Quebec City, or relax in the tropical haven of the Caribbean. Whether you're in the mood for lively city escapades or tranquil natural getaways, our carefully chosen destinations at RawFares promise unforgettable experiences. Let your travel dreams soar as you explore the most sought-after and enchanting places in the world.</p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 destination" style={{marginBottom: "20px"}}>
                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated" onClick={(e) => {handleCardClick("City", "yyc", "calgary"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}}>
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("yyc", "calgary"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresCA/home_destination/Calgary.png`} alt="destination" style={{height: '237px'}} onClick={() => handleCardClick("yyc", "calgary")} />
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">CAD230<sup>.89</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Calgary</div>
                        </div>
                        
                    </div>       
                    

                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated">
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("City", "yyz", "toronto"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresCA/home_destination/Toronto.png`} alt="destination" style={{height: '237px'}}/>
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">CAD150<sup>.99</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Toronto</div>
                        </div>
                        
                    </div>

                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated">
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("City", "yvr", "vancouver"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresCA/home_destination/Vancouver.png`} alt="destination" style={{height: '237px'}}/>
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">CAD90<sup>.67</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Vancouver</div>
                        </div>
                        
                    </div>
                <p style={{fontSize: '11px', color: '#959595', fontFamily: 'sans-serif'}}><b>Note:</b> All fares are quoted in CAD.
                    Last updated on Thursday 08/17/2023 at 05:00 AM, the fares mentioned above are for Round Trip flight tickets and inclusive of fuel surcharges, service fee and taxes . Based on historical data, these fares are subject to change without prior notice and cannot be guaranteed at the time of booking. Kindly go through our terms and conditions before booking
                </p>
                </div>




            </div>

            <div className="box-text grayBg" style={{marginBottom: '2px'}}>
                <div className="container">
                    <div className="row">
                        <div className="holder">
                            <div className="heading">
                            <h2>Best Airfare Flight Deals</h2>
                                <p>Open the door to endless possibilities with our specially selected deals from leading airlines like Air Canada, WestJet, and Porter Airlines. We're not just offering flights; we're providing a gateway to extraordinary experiences, whether you're chasing sunsets on a sandy beach or delving into the history of charming cities. Our curated airline deals ensure your journey is not just a ticket but a seamless passage to the destinations waiting for your exploration. Discover more, pay less—because your next adventure should be as limitless as your dreams.</p>
                            </div>
                            <OwlCarousel className='owl-theme' loop margin={10} >
                                <div className="project-item mb-1">
                                <div onClick={(e) => {handleCardClick("Airline", "ac", "air-canada"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresCA/home_airlines/AirCanada.png`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Air Canada</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "ts", "air-transat"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresCA/home_airlines/Air-Canada.png`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Air Transat</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "9m", "central-mountain-air"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresCA/home_airlines/Central-Mountain.png`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Central Mountain Air</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "pd", "porter-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresCA/home_airlines/Porter-Airlines.png`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Porter Airlines</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "wg", "sunwing-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresCA/home_airlines/Air-Canada.png`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Sunwing Airlines</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "ws", "westJet"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresCA/home_airlines/Westjet.png`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Westjet</p>

                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            
            <div className="clearfix"></div>
            {/* <!-- Text Box Start here --> */}
            <div className="box-text">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3>Pocket-Friendly Flights Anywhere</h3>
                            <p>Welcome to RawFares.ca, your go-to hub for all things travels. At RawFares, we take pride in making travel affordable for countless adventurers. Our state-of-the-art search tool makes trimming down your airline expenses as easy as a few clicks.</p><br/>
                            <h3>We are with you throughout the way!</h3>
                            <p>Day or night, our team of travel enthusiasts collaborates with various airlines and top travel platforms. Whether you're planning a romantic escape or embarking on a spontaneous business trip, rest assured that worrying about flight costs is a thing of the past. At RawFares, we're not just facilitating journeys; we're unlocking a world where your travel dreams meet budget-friendly reality.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Text Box End here --> */}
            <div className="clearfix"></div>
        </div>
        <Footer />
        </div>
  )
}
