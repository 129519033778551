/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from '../../resources/loader/loader.gif';
//import '../../resources/font/bootstrap-icons.css';
//import '../../resources/css/meanmenu.css';
import '../../resources/css/footer-style.css';
import Mail from '../../resources/images/icons/mailing_icon_.png';
import fb from '../../resources/images/icons/facebook-icon.svg';
import insta from '../../resources/images/icons/instagram-icon.svg';
import twit from '../../resources/images/icons/twitter-icon.png';
import SITE_CONFIG from '../../SiteController/SiteController';
//import '../../resources/css/meanmenu.css';
//import '../../resources/jc/owl.carousel.min.css'

export default function Footer() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
   
        // Step 2: Create a state variable for the checkbox status
        const [isChecked, setIsChecked] = useState(false);

        const handleCheckboxChange = (event) => {
          setIsChecked(event.target.checked);
        };

const [isLoading, setIsLoading] = useState(false);
    const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"GBP",
        siteId: siteID,
        departDate:"",
        countryName:"UK",
        countryCode:"UK",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
    
      const handleAirlineClicked = async () => {

            const navigationPath = airlines.pageValue.length === 2
              ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
              : `/flights-to/${airlines.url}-${airlines.pageValue}`;
        
            const newTab = window.open(navigationPath, '_blank');
            if (newTab) {
                //console.log('Received data in new tab:');
              newTab.onload = function() {
                newTab.postMessage(window.location.origin);
              };
            } else {
              //console.error('Failed to open new tab. Please check your browser settings or extensions.');
            }
        
          };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const openLink = (url) => {
		window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
	  };
  return (
    <>
     
        
        {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
      <footer className="footer-area">
            <div className="row">
                <div className="col-12 col-md-12">
                    <div className="container">
                        {/* <!--Subscribe box Start here --> */}
                        <div className="Boxsubscribe" style={{display: 'none'}}>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="left">
                                        <span className="bell-icon">
                                            <img src={Mail} alt="bell icon" />
                                        </span>
                                        <div>
                                            <h4>Subscribe to our Newsletter</h4>
                                            <p>Get latest offers from Farehutz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    {/* <!-- Subscribebox--> */}
                                    {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isChecked} onChange={handleCheckboxChange}></input> */}
                                    <div className="subscribe_div">
                                        <div className="subscribe">
                                            <div className="column2">
                                                <div className="select-dropdown drop-arrow">
                                                    <input type="text" id="subscribeEmail" placeholder="E-mail address" className="inputform" autoComplete="false" />
                                                </div>
                                                <div className="row d-block d-sm-none">
                                                    <div className="col-12 inputSet nofill mb-2">
                                                        {/* <label className="float-start p-0"> <input className="subscribeCheck" type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/> <span className="checkmark"></span> </label> */}
                                                        
                                                        By clicking I have read and agree to the
                                                    </div>
                                                </div>
                                                <button type="button" disabled={!isChecked} className="btn btn-primary" style={{height:'45px', borderRadius: '4px 4px 4px 4px', width: '150px'}}>subscribe</button>
                                            </div>
                                            
                                            <div id="subscribeError"></div>
                                            <div className="clearfix"></div>
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isChecked} onChange={handleCheckboxChange} style={{margin: '3px'}}></input>
                                                By clicking, I have read and agree to the
                                            <div className="row d-none d-sm-block">
                                                <div className="col-12 inputSet nofill mb-2">
                                                    {/* <label className="float-start p-0"> <input className="subscribeCheck" checked={isChecked} onChange={handleCheckboxChange} type="checkbox"/> <span className="checkmark"></span> </label> */}
                                                    
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="sucessfullMsg" style={{display: "none"}}>
                                            <div className="thanks"><img src="index.html" alt="" className="pull-left" /> Thanks</div>
                                            <div className="msg">
                                                Your email ID <br />
                                                <span id="email2">iliyas.ahmad@Travels.com</span> <br />
                                                <span id="submitSuccess2"> has been added successfully</span>
                                            </div>
                                            <small className="text">We will notify you for our Best Deals and offers</small>
                                        </div>
                                    </div>

                                    {/* <!-- Subscribebox--> */}
                                </div>
                            </div>
                        </div>
                        {/* <!--Subscribe box End here --> */}
                    </div>
                    {/* <!--footer box Start here --> */}
                    <div className="top-footer">
                        <div className="container">
                            <div className="holder">
                                
                                <div className="quickLinks">
                                    <div className="row">
                                        <div className="col-md-3 col-12">
                                        <h5>Traveler Assistance</h5>
                                            <ul>
                                                <li><Link to={`${apiIP}/about`}>About Us</Link></li>
                                                <li><Link to={`${apiIP}/online-check-in`} >Online Check-in</Link></li>
                                                {/* <li>Check Flight Status</li> */}
                                                <li><Link to={`${apiIP}/airline-baggage-fee`} onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Airline Baggage Fees</Link></li>
                                                <li><Link to='/taxes-and-fees' onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Taxes & Fees</Link></li>
                                                <li><Link to='/post-ticketing-fee'>Post Ticketing Fees</Link></li>
                                                <li><Link to='/our-services-fee' onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Our Service Fees</Link></li>
                                                {/* <li>Newsletter</li>
                                                <li>Client Testimonial</li>
                                                <li>Blogs</li> */}
                                                <li><Link to='/contact-us'>Contact Us</Link></li>
                                            </ul>
                                            
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <h5>Popular Airlines</h5>
                                            <ul>
                                                <li><a href={`${apiIP}/airline/air-canada-ac-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "ac", "air-canada")}}>Air Canada</a></li>
                                                <li><a href={`${apiIP}/airline/air-transat-ts-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "ts", "air-transat")}}>Air Transat</a></li>
                                                <li><a href={`${apiIP}/airline/central-mountain-air-9m-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "9m", "central-mountain-air")}}>Central Mountain Air</a></li>
                                                <li><a href={`${apiIP}/airline/porter-airlines-pd-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "pd", "porter-airlines")}}>Porter Airlines</a></li>
                                                <li><a href={`${apiIP}/airline/sunwing-airlines-wg-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "wg", "sunwing-airlines")}}>Sunwing Airlines</a></li>
                                                <li><a href={`${apiIP}/airline/westJet-ws-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "ws", "westJet")}}>WestJet</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-6 d-none d-sm-block">
                                        <h5>Popular Destinations</h5>
                                            <ul>
                                                <li><a href={`${apiIP}/flights-to/calgary-yyc`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "yyc", "calgary")}}>Calgary</a></li>
                                                <li><a href={`${apiIP}/flights-to/edmonton-yeg`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "yeg", "edmonton")}}>Edmonton</a></li>
                                                <li><a href={`${apiIP}/flights-to/halifax-yhz`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "yhz", "halifax")}}>Halifax</a></li>
                                                <li><a href={`${apiIP}/flights-to/ottawa-yow`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "yow", "ottawa")}}>Ottawa</a></li>
                                                <li><a href={`${apiIP}/flights-to/toronto-yyz`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "gla", "toronto")}}>Toronto</a></li>
                                                <li><a href={`${apiIP}/flights-to/vancouver-yvr`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "yvr", "vancouver")}}>Vancouver</a></li>

                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <h5>Legal</h5>
                                            <div className="link footer-contact-links">
                                                {/* <a href="aboutus.html"> Terms & Conditions </a> &nbsp; | &nbsp; <a href="contactus.html"> Contact Us </a> <br/> */}
                                                 Privacy Policy  &nbsp; | &nbsp;  <Link to='/terms-and-condition' style={{color: 'white'}}>Terms & Conditions</Link>  <Link to='/sitemap'>Sitemap</Link> 
                                            </div>
                                            <div className="footer-contact-links">
                                                <h5>CA Address:</h5>
                                                 55 village centre Pl,C/o:Incorp Pro,Suite 200, Mississauga, Ontario, Canada, L4Z1V9
                                            </div>
                                            <div className="social-media d-sm-block">
                                                <h5>Follow Us :</h5>
                                                <div className="clearfix"></div>
                                                <a href="https://www.facebook.com/rawfares" target="_blank" rel="noreferrer" title="Facebook" className="icoFacebook">
                                                    <img src={fb} alt="facebook" />
                                                </a>
                                                {/* <a href="javascrip:;" style={{cursor: 'pointer'}} title="Instagram"  className="icoinstagram">
                                                    <img src={insta} alt="instagram" />
                                                </a> */}
                                                <a href="https://twitter.com/rawfares" target="_blank" title="Twitter" rel="noreferrer" className="icoTwitter">
                                                    <img src={twit} alt="twitter" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-block">
                        <div className="container">
                        <p style={{textAlign: "center ", color: 'white', fontSize: '13px'}}>Rawfares.ca is represented by Holiay Breakz Group LLC which is registered in 55 village centre Pl,C/o:Incorp Pro,Suite 200, Mississauga, Ontario, Canada, L4Z1V9<br />Rawfares.ca is an independent travel portal with no third party association. By using Rawfares.ca, you agree that Rawfares is not accountable for any loss - direct or indirect, arising of offers, materials or links to other sites found on this website. In case of queries, reach us directly at our Contact Number +1-888-679-3941 or, simply email at support@rawfares.ca</p>
                            <div className="copyright"> Copyrights &copy; 2022-2024 rawfares.ca, All Rights Reserved. </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <!--container-footer--> */}
        </>
  )
}
