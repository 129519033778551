/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MetaTitle from './metaTitle';
import '../resources/css/contact-style.css';
import Footer from '../components/Footer/footer';
import SITE_CONFIG from '../SiteController/SiteController';
//import background from "../resources/images/search_banner/Homepage-Main-banner.png";

export default function About() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    {/* <Helmet>
        <title>About us - RawFares</title>
        <meta name="description" content="This is About page of www.rawfares.co.uk" />
        {/* Other meta tags */}
        <link rel="canonical" href={`${apiIP}/about`} />
        {/* Additional meta tags for homepage 
      </Helmet> */}
    <MetaTitle pageTitle='Who We Are? | Know Everything About Us - RawFares' pageDescription='Make your trip a memorable moment with RawFares: one of the best OTA. Get the best flight deals & discounts on online ticket reservations.' url={`${apiIP}/about`} />
    <div class="container-fluid" /*style={{marginTop: '75px'}}*/>
			
			<div class="row">
			<div class="content_page_title1" style={{marginTop: '75px'}}>
			<div class="container">
			<h1><img src="resources/images/icons/tnc-icon.svg" alt=""/> </h1>
			</div>
			</div>
				
		<div class="textBox">
        <div class="container">
          <div class="row">
			<div class="content-wrap">
                <p>At RawFares.ca, we go beyond the ordinary, reshaping the travel landscape and unlocking a world of
possibilities for every adventurer. Our commitment is straightforward yet impactful: to offer you not just
the best flights to major destinations, but also to the undiscovered corners that call out to the curious
spirit.</p>
                <p> Our journey begins with a deep passion for exploration, a fondness for wanderlust, and a vision to make
travel accessible to everyone. RawFares has evolved into a one-stop sanctuary for those in search of
more than just flights—a place for an immersive and budget-friendly travel experience. Whether your
dream involves strolling through the lively streets of a bustling city or seeking the serenity of a hidden
gem, RawFares serves as your compass in the vast world of travel.</p>
                <p>We take pride in being your gateway to both prominent destinations and the off-the-beaten-path
treasures waiting for your exploration. From iconic skylines to hidden wonders, our platform swings
open the doors to a multitude of destinations, each possessing its unique charm and allure. We firmly
believe that every journey should weave a tapestry of diverse experiences, and our extensive range of
flight options embodies this philosophy.</p>
				
				<div class="clearfix"></div>
				
				<div class="right_image">
					<img src="resources/images/about-img1.png" style={{width: '100%'}} alt="" />
				  </div>
          <h2>Why Choose RawFares?</h2>
                 <p> Navigating the intricacies of airline policies can feel like a maze. That&#39;s where RawFares.ca steps in to
simplify the complexities. We go beyond offering a variety of flight options; we demystify airline policies,
ensuring you embark on your journey well-informed and empowered. Whether it's understanding
baggage regulations or cancellation policies, we've got you covered, providing the confidence to
navigate the skies seamlessly.</p> 
                 <p>RawFares.ca is more than just a platform for flights; it's a curator of exclusive deals crafted to enhance
your travel experience. Through strategic partnerships with airlines, we bring you deals that go beyond
the ordinary. Imagine unlocking discounts and perks that not only make your journey affordable but also
enrich it with added value. At RawFares.ca, we believe every traveler deserves the VIP treatment, and
our exclusive deals are designed with this ethos in mind.</p>
				  
            <h3>Our Commitment: Clear and Genuine</h3>
                <p>
                Transparency is at the heart of RawFares.ca. We recognize that trust is something that is earned, and we
are dedicated to nurturing a relationship grounded in authenticity. Our platform mirrors our
commitment to providing you with precise information, impartial insights, and a human touch in the
digital landscape of travel planning.</p>
                <p>Embarking on a journey with RawFares.ca is more than just reaching a destination; it&#39;s about savoring
the excitement, enjoying the exploration, and cherishing the moments in between. Our user-friendly
interface ensures that discovering the perfect flight is a seamless experience. Navigating through a
multitude of options, exploring exclusive deals, and understanding airline policies become a delightful
prelude to your upcoming adventure.</p>
				  
             <h3>Reach out to Us</h3>
                <p>
                At RawFares.ca, we value the strength of connections. Our customer support isn't just a service; it's a
personal link, a bridge connecting your questions to our commitment to making sure your travel dreams
take flight. Whether you have a query, a special request, or need advice on your travel plans, our team is
here and ready to assist, guiding you every step of the way.</p>
                <p>In the expansive world of digital travel, RawFares.ca is more than a website; it's a companion, a guide,
and a facilitator of dreams. Come along with us as we redefine travel, one seamless journey at a time.
RawFares.ca - Navigating Dreams, Elevating Journeys.</p>
              </div>	
          </div>
        </div>
      </div>
				
			
				
		      </div>
				
			</div> 
      <Footer />
      </>
  )
}

