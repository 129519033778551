/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback} from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import { useNavigate } from "react-router-dom";
import SITE_CONFIG from '../SiteController/SiteController';
import Loader from '../resources/loader/deepLoader.gif'

export default function DeepLink() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { apiIP, siteName, siteID } = SITE_CONFIG;
    const [responseData, setResponseData] = useState(null);
    const [selectTripType, setSelectTripType] = useState(parseInt(params.get('tripType')));
    const cabinTypeMapping = {
      Economy: "1",
      Business: "3",
      PremiumEconomy: "2",
      First: "4",
    };
    const [formValues, setFormValues] = useState({
        searchID: params.get('wego_click_id'),
        client: 2,
        segment: [
          {
            originAirport: params.get('origin'),
            destinationAirport: params.get('destination'),
            travelDate: params.get('departDate'),
            originCountry: "UK",
            destinationCountry: "ALL",
          },
          {
            originAirport: "",
            destinationAirport: "",
            travelDate: params.get('returnDate'),
          },
        ],
        searchDirectFlight: false,
        flexibleSearch: false,
        tripType: params.get('tripType'),
        adults: params.get('adult'),
        child: params.get('child'),
        infants: params.get('infant'),
        infantsWs: params.get('infantWs'),
        cabinType: cabinTypeMapping[params.get('cabinClass')] || "",
        airline: "All",
        currencyCode: "GBP",
        siteId: siteID,
        source: params.get('utm_source') || "Online",
        media: siteName,
        sID: "",
        rID: "",
        locale: "en",
        isNearBy: false,
        limit: 200,
        userIP: "",
        serverIP: "",
        device: "",
        browser: browserName,
        // ...(isRound ? {} : { dateOfReturn }),
      });
      const navigate = useNavigate();
      const handleSubmit = async (e) => {
          //e.preventDefault();
      
          const deviceName = isMobile
            ? "Mobile Device"
            : isDesktop
            ? "Desktop"
            : isAndroid
            ? "Android"
            : isIOS;
          const response = await axios.get("https://api.ipify.org?format=json");
          const userIP = response.data.ip;
          const newSearchID = uuidv4().slice(0, 16);
      
          
      
          // Convert formValues to JSON format
          
      
          // Navigate to the result page with the searchID in the URL
          //navigate(`/${newSearchID}`);
          
          let updatedSegment;
          if (selectTripType === 2) {
            updatedSegment = [
              {
                originAirport: formValues.segment[0].originAirport,
                destinationAirport: formValues.segment[0].destinationAirport,
                travelDate: formValues.segment[0].travelDate,
                originCountry: "UK",
                destinationCountry: "ALL",
              },
              {
                originAirport: formValues.segment[0].destinationAirport,
                destinationAirport: formValues.segment[0].originAirport,
                travelDate: formValues.segment[1].travelDate || "",
              },
            ];
          } else {
            if(selectTripType === 1){
            updatedSegment = [
              {
                originAirport: formValues.segment[0].originAirport,
                destinationAirport: formValues.segment[0].destinationAirport,
                travelDate: formValues.segment[0].travelDate,
                originCountry: "UK",
                destinationCountry: "ALL",
              },
              
            ];
          }}
          //console.warn(updatedSegment);
          const tripTypeAsString = selectTripType.toString();
          const updatedFormValues = {
            ...formValues,
            tripType: tripTypeAsString,
            segment: updatedSegment,
            searchID: newSearchID,
            userIP: userIP,
            device: deviceName,
          };
        
          //setFormValues(updatedFormValues);
      
          const jsonData = JSON.stringify(updatedFormValues);
          console.log(jsonData);
          //console.log(updatedFormValues);
      
          //searchFlights();
          try {
            const response = await axios.post(
              `${apiIP}:7080/api/flight-result`,
              updatedFormValues
            );
      
            if (response.status === 200) {
              setResponseData(response.data); // Update the state with the response data
              //console.log(response.data);
              const mergedData = {
                  responseData: response.data,
                formData: updatedFormValues,
              };
              navigate(`/flight-list?searchID=${newSearchID}`, { state: mergedData });
            } else {
              //console.error("Request failed with status:", response.status);
            }
          } catch (error) {
            //console.error("Error making POST request:", error);
          }
        };
        useEffect(() => {
          if (
            formValues.segment[0].originAirport &&
            formValues.segment[0].destinationAirport &&
            formValues.segment[0].travelDate
          ) {
            handleSubmit();
          }
        }, [formValues])
        
        
          
        
          // Rest of your code, including handleSubmit and other hooks...
        
      
  return (
    <div style={{width: '300px',margin: '0 auto'}}>
      <img src={Loader} alt="" style={{ width: '50%', marginTop: '100px'}} />
      <p>Please Wait for while...</p>
      
    </div>
  )
}
