/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import 'jquery-validation';
import '../resources/css/Contactus.css'
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';
import Captcha from '../AddOn/Capcha';
import ReCAPTCHA from 'react-recaptcha';

export default function ContactUs() {

    const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaImageUrl, setCaptchaImageUrl] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
    const { apiIP, siteName, siteID } = SITE_CONFIG;
    useEffect(() => {
        $('.numbersOnly').keyup(function () {
            if (this.value !== this.value.replace(/[^0-9\\.]/g, '')) {
               this.value = this.value.replace(/[^0-9\\.]/g, '');
            }
        });
$('.textOnly').keyup(function () {
              if (this.value !== this.value.replace(/[^a-zA-Z ]+/g, '')) {
                   this.value = this.value.replace(/[^a-zA-Z ]+/g, '');
                }
        });			
$("#contactForm").validate({
     rules:{
         firstName : {
             required : true
         },
         lastName :{
             required : true
         },
         email :{
             required : true,
             email : true
         },
         phone :{
             required : true
         },
         message :{
             required : true
         }
     },
      errorPlacement: function(error, element) {   },
       submitHandler: function(form) {
                var data = {
                        firstName: $("#firstName").val(),
                        lastName: $("#lastName").val(),
                        email : $("#email").val(),
                        phone : $("#phone").val(),
                        message : $("#message").val()
                            }
                        $.ajax({
                            url: '/contactus/submit',
                            type: 'post',
                            contentType: 'application/json; charset=utf-8',
                            data: JSON.stringify(data),
                            dataType: "text",
                            success: function(response) {
                                alert(response)
                                if(response === 'true')
                                $("#reviewSuccess").text("Contact Query Submit Successfully!").css("color", "green");
                                else
                                $("#reviewSuccess").text("Some Technical Issue Please try later!").css("color", "red");	
                            }
                        });
                        
            
      }
 })
    }, [])
    
      const handleCaptchaChange = (e) => {
        setCaptchaValue(e.target.value);
      };
    
      const handleCaptchaVerification = async () => {
        // Send CAPTCHA value to server for verification
        try {
          const response = await fetch('/verify-captcha', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ captchaValue }),
          });
          const { success } = await response.json();
      
          if (success) {
            // Proceed with form submission
            console.log('CAPTCHA verification successful');
            // Submit form
          } else {
            // Display error message
            console.error('CAPTCHA verification failed');
          }
        } catch (error) {
          console.error('Error verifying CAPTCHA:', error);
        }
      };

      const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaVerify = (response) => {
    // Handle CAPTCHA verification here
    console.log("CAPTCHA verified:", response);
    // Set the state to indicate CAPTCHA verification success
    setCaptchaVerified(true);
  };

  const handleSubmit = () => {
    // Handle form submission here, only if CAPTCHA is verified
    if (captchaVerified) {
      console.log("Form submitted successfully!");
      // You can proceed with form submission logic here
    } else {
      console.log("Please complete the CAPTCHA!");
      // Optionally, display an error message or prevent form submission
    }
  };
      
  return (
    <>
    <MetaTitle pageTitle='Customer Support & Services on Rawfares.co.uk' pageDescription='You can directly contact us for customer support & services call at 020-3627-9333.' url={`${apiIP}/contact-us`} />
    <body>
    
    {/* <!--Header Ends Here--> */}
    <div class="blog-head1">
        {/* <span>Contact Us </span> */}
    </div>
    <div style={{backgroundColor: '#ffffff', marginTop: '50px'}} >
    <div class="contactus">
        <div class="contact-details">
            <h3>Contact Us </h3>
            <span><i class="fa fa-map-marker"></i> &nbsp; Address: 55 village centre Pl,C/o:Incorp Pro,Suite 200, Mississauga, Ontario, Canada, L4Z1V9</span>
            <span><i class="fa fa-envelope"></i> &nbsp;<a href="mailto:support@rawfares.ca">support@rawfares.ca</a></span>
            <span><i class="fa fa-phone"></i> &nbsp;+1-888-679-3941</span>
            {/* <!-- <span><i class="fa fa-phone"></i> &nbsp;1-800-404-0025-Consumer Service</span> --> */}
            <hr/>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3110.9405274523306!2d-75.21420562492851!3d38.76506805450267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b8b90d99b4ec3f%3A0xe2fe2d10223fc02d!2s6192%20Coastal%20Hwy%2C%20Lewes%2C%20DE%2019958%2C%20USA!5e0!3m2!1sen!2sin!4v1682068723134!5m2!1sen!2sin" width="100%" height="350" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.274148007924!2d-79.64580932190032!3d43.60083319196897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b4730dd18f7d1%3A0x27a61e0de4bdb892!2s55%20Village%20Centre%20Pl%2C%20Mississauga%2C%20ON%20L4Z%201V9%2C%20Canada!5e0!3m2!1sen!2sin!4v1707907267654!5m2!1sen!2sin" style={{border: '0'}} width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="contact-form">
            <h3>Get in Touch with Us </h3>
            <form   onSubmit={handleSubmit} >
                <div class="flname">
                    <div><label>First Name</label><br/><input type="text" path="firstName" class="textOnly" /></div>
                    <div><label>Last Name</label><br/><input type="text" path="lastName" class="textOnly" /></div>
                </div>
                <div class="phonemail">
                    <div><label>Phone No.</label><br/><input type="text" path="phone" class="numbersOnly" /></div>
                    <div><label>Email</label><br/><input type="text" path="email" /></div>
                </div>
                <div class="comment">
                    <label>Your Comment</label>
                    <textarea path="message"></textarea>
                </div>
                <div class="submit-form">
                    <button type="submit">Submit</button>
                </div>
                <ReCAPTCHA
        sitekey="6LfN-bUpAAAAAIrV0d9rd0t1ugLeY9ZAkZeyWmKq" // Replace 'YOUR_SITE_KEY' with your actual reCAPTCHA site key
        onChange={handleCaptchaVerify}
      />
            </form>
			<div id="reviewSuccess"></div>
            
      <button onClick={handleSubmit}>Submit</button>
            {showCaptcha && (
        <Captcha src={captchaImageUrl} onChange={handleCaptchaChange} />
      )}

      {showCaptcha && (
        <button onClick={handleCaptchaVerification}>Verify CAPTCHA</button>
      )}
        </div>
    </div>
    </div>
    <Footer />
</body>
</>
  )
}
