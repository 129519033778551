/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Home from '../pages/home';
//import FlightList from '../pages/FlightList';
import Flightsearchresult from '../pages/Flight-search-result';
import Collecting from '../pages/collecting';
import About from '../pages/About'
import PaymentPage from '../pages/PaymentPage';
import Card from '../pages/card';
import Confirmation from '../pages/confirmation';
import AirlinesDetails from '../pages/airlinesDetails';
import DestinationDetails from '../pages/destinationDetails'
import GoogleMeta from '../MetaController/GoogleMeta';
import NotFoundPage from '../pages/404';
import OnlineCheckIn from '../pages/online-check-in';
import CheckIn from '../pages/CheckIn';
import CookieNotice from '../pages/CookieNotice';
import TaxesFees from '../pages/Taxes&Fees';
import PostTicketing from '../pages/PostTicketing';
import BaggageFee from '../pages/BaggageFee';
import OurServicesFee from '../pages/OurServicesFee';
import ContactUs from '../pages/ContactUs';
import TermsCondition from '../pages/termsCondition';
import Sitemap from '../pages/sitemap';
import DeepLink from '../MetaController/DeepLink';
//import Contact from '../pages/Contact'
//import About from '../pages/About'
// import flightResult from '../pages/flight-result'
// import FlightSearchResults from '../pages/flight-search-results'
// import about from '../pages/about'
//import Demo from '../pages/Demo'
// import ThankYou from '../pages/ThankYou'
// import SearchResultList from './../pages/SearchResultList'
// import TourDetails from './../pages/TourDetails'
// import Tours from './../pages/Tours'

const ScrollToTop = () => {
   const { pathname } = useLocation();
 
   useEffect(() => {
     window.scrollTo(0, 0);
   }, [pathname]);
 
   return null;
 };

const Router = ({ setHeaderColor }) => {
   // Define your list of URLs for the sitemap
  const urls = [
   { url: '/', changefreq: 'daily', priority: 1.0 },
   { url: '/about', changefreq: 'monthly', priority: 0.7 },
   // Add more URLs for other pages as needed
 ];

 // Simulate generating the sitemap within the component
 const generateSitemap = () => {
   const sitemapContent = `
     <?xml version="1.0" encoding="UTF-8"?>
     <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
       ${urls.map((url) => `
         <url>
           <loc>${url.url}</loc>
           <changefreq>${url.changefreq}</changefreq>
           <priority>${url.priority}</priority>
         </url>`).join('\n')}
     </urlset>
   `;

   // Log the generated sitemap content (for demonstration)
   //console.log(sitemapContent);
 };

 // Generate the sitemap when the component mounts
 useEffect(() => {
   generateSitemap();
 }, []);
 
   return (
      <>
      <ScrollToTop />
      
      <Routes>
         {/* <Route path='/*' element={<ScrollToTop />} /> */}
         {/* <Route path='/home' element={<Navigate to='/'/>} /> */}
         
         {/* <Route path='/flight-result' Component={flightResult} /> */}
         <Route exact path='/' element={<Home setHeaderColor={setHeaderColor('#fff')}/>} />
         <Route path='/flight-List' element={<Flightsearchresult setHeaderColor={setHeaderColor('#fff')}/>}  />
         <Route path='/collecting' element={<Collecting />} />
         <Route exact path='/about' element={<About />} />
         <Route path='/payment/:searchID/:resultID' element={<PaymentPage/>} />
         <Route path='/card' element={<Card/>} />
         <Route path='/confirmation/:searchID' element={<Confirmation/>} />
         <Route path='/airline/:airlineName' element={<AirlinesDetails />} />
         <Route path='/flights-to/:airlineName' element={<DestinationDetails />} />
         <Route path='/flights/to/service' element={<GoogleMeta/>} />
         <Route path='/searchFlight/?' element={<DeepLink/>} />
         <Route exact path='/about' element={<About/>} />
         <Route exact path='/online-check-in' element={<CheckIn />} />
         <Route exact path='/taxes-and-fees' element={<TaxesFees />} />
         <Route exact path='/post-ticketing-fee' element={<PostTicketing />} />
         <Route exact path='/airline-baggage-fee' element={<BaggageFee />} />
         <Route exact path='/our-services-fee' element={<OurServicesFee />} />
         <Route exact path='/contact-us' element={<ContactUs />} />
         <Route exact path='/terms-and-condition' element={<TermsCondition />} />
         <Route path='/sitemap' element={<Sitemap/>} />
         <Route path='/sitemap.xml' element="../../public/sitemap.xml" />
         <Route component={NotFoundPage} />
         <Route path='*' element={<NotFoundPage />} />
         <Route path='/not-found' element={<NotFoundPage />} />
         {/* <Route path='/collection' element={<Collecting />} /> */}
         {/* <Route path='/flight-List' Component={FlightSearchResults} /> */}
         {/* <Route exact path='/demo' element={<Demo/>} />
         <Route exact path='/contact' Component={Contact} />
          */}
         {/* <Route path='/tours' element={<Tours/>} />
         <Route path='/tours/:id' element={<TourDetails/>} />
         <Route path='/login' element={<Login/>} />
         <Route path='/register' element={<Register/>} />
         <Route path='/thank-you' element={<ThankYou/>} />
         <Route path='/tours/search' element={<SearchResultList/>} /> */}
        
      </Routes>
      </>
   )
}

export default Router